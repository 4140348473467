var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.items.length)?_c('ul',{ref:"tagAgentsRef",staticClass:"vertical dropdown menu mention--box bg-white text-sm dark:bg-slate-700 rounded-md overflow-auto absolute w-full z-20 pt-2 px-2 pb-0 shadow-md left-0 leading-[1.2] bottom-full max-h-[12.5rem] border-t border-solid border-slate-75 dark:border-slate-800",class:{
      'border-b-[0.5rem] border-solid border-white dark:!border-slate-700':
        _setup.items.length <= 4,
    }},_vm._l((_setup.items),function(agent,index){return _c('li',{key:agent.id,staticClass:"flex items-center p-2 rounded-md last:mb-2",class:{
        'bg-slate-50 dark:bg-slate-800': index === _setup.selectedIndex,
        'last:mb-0': _setup.items.length <= 4,
      },attrs:{"id":`mention-item-${index}`},on:{"click":function($event){return _setup.onAgentSelect(index)},"mouseover":function($event){return _setup.onHover(index)}}},[_c('div',{staticClass:"mr-2"},[_c('woot-thumbnail',{attrs:{"src":agent.thumbnail,"username":agent.name,"size":"32px"}})],1),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full overflow-hidden whitespace-nowrap text-ellipsis"},[_c('h5',{staticClass:"mb-0 overflow-hidden text-sm text-slate-800 dark:text-slate-100 whitespace-nowrap text-ellipsis",class:{
            'text-slate-900 dark:text-slate-100': index === _setup.selectedIndex,
          }},[_vm._v("\n          "+_vm._s(agent.name)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"overflow-hidden text-xs whitespace-nowrap text-ellipsis text-slate-700 dark:text-slate-300",class:{
            'text-slate-800 dark:text-slate-200': index === _setup.selectedIndex,
          }},[_vm._v("\n          "+_vm._s(agent.email)+"\n        ")])])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }