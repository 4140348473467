<script>
export default {};
</script>

<template>
  <li
    class="list-none my-1 mx-0 border-b border-slate-50 dark:border-slate-700"
    :tabindex="null"
    :aria-disabled="true"
  />
</template>
