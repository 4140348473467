var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col w-full gap-2 max-h-[312px]"},[_c(_setup.CalendarAction,{attrs:{"view-mode":_setup.MONTH,"calendar-type":_vm.calendarType,"first-button-label":_setup.monthName(
        _vm.calendarType === _setup.START_CALENDAR ? _vm.startCurrentDate : _vm.endCurrentDate
      ),"button-label":_setup.yearName(
        _vm.calendarType === _setup.START_CALENDAR ? _vm.startCurrentDate : _vm.endCurrentDate
      )},on:{"prev":_setup.onClickPrev,"next":_setup.onClickNext,"setView":_setup.setViewMode}}),_vm._v(" "),_c(_setup.CalendarWeekLabel),_vm._v(" "),_vm._l((_setup.weeks(_vm.calendarType)),function(week){return _c('div',{key:week[0].getTime(),staticClass:"grid max-w-md grid-cols-7 gap-2 mx-auto overflow-hidden rounded-lg"},_vm._l((week),function(day){return _c('div',{key:day.getTime(),staticClass:"flex relative items-center justify-center w-9 h-8 py-1.5 px-2 font-medium text-sm rounded-lg cursor-pointer",class:_setup.dayClasses(day),on:{"mouseenter":function($event){return _setup.emitHoveredEndDate(day)},"mouseleave":function($event){return _setup.emitHoveredEndDate(null)},"click":function($event){return _setup.emitSelectDate(day)}}},[_vm._v("\n      "+_vm._s(day.getDate())+"\n      "),(
          (_setup.isInRange(day) || _setup.isHoveringInRange(day)) &&
          _setup.isNextDayInRange(day) &&
          !_setup.isLastDayOfMonth(day) &&
          _setup.isInCurrentMonth(day)
        )?_c('span',{staticClass:"absolute bottom-0 w-6 h-8 ltr:-right-4 rtl:-left-4 bg-woot-50 dark:bg-woot-800 -z-10"}):_vm._e()])}),0)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }