import { render, staticRenderFns } from "./Twitter.vue?vue&type=template&id=06b51195&scoped=true&"
import script from "./Twitter.vue?vue&type=script&lang=js&"
export * from "./Twitter.vue?vue&type=script&lang=js&"
import style0 from "./Twitter.vue?vue&type=style&index=0&id=06b51195&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b51195",
  null
  
)

export default component.exports