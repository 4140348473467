var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.to,"custom":"","active-class":"active"},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('li',{staticClass:"font-medium h-7 my-1 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md dark:hover:bg-slate-800",class:{
      'bg-woot-25 dark:bg-slate-800': isActive,
      'text-ellipsis overflow-hidden whitespace-nowrap max-w-full':
        _vm.shouldTruncate,
    },on:{"click":navigate}},[_c('a',{staticClass:"inline-flex text-left max-w-full w-full items-center",attrs:{"href":href}},[(_vm.icon)?_c('span',{staticClass:"inline-flex items-center justify-center w-4 rounded-sm bg-slate-100 dark:bg-slate-700 p-0.5 mr-1.5 rtl:mr-0 rtl:ml-1.5"},[_c('fluent-icon',{staticClass:"text-xxs text-slate-700 dark:text-slate-200",class:{
            'text-woot-500 dark:text-woot-500': isActive,
          },attrs:{"icon":_vm.icon,"size":"12"}})],1):_vm._e(),_vm._v(" "),(_vm.labelColor)?_c('span',{staticClass:"inline-flex rounded-sm bg-slate-100 h-3 w-3.5 mr-1.5 rtl:mr-0 rtl:ml-1.5 border border-slate-50 dark:border-slate-900",style:({ backgroundColor: _vm.labelColor })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"},[_c('span',{staticClass:"text-sm text-slate-700 dark:text-slate-100",class:{
            'text-woot-500 dark:text-woot-500': isActive,
            'text-ellipsis overflow-hidden whitespace-nowrap max-w-full':
              _vm.shouldTruncate,
          },attrs:{"title":_vm.menuTitle}},[_vm._v("\n          "+_vm._s(_vm.label)+"\n        ")]),_vm._v(" "),(_vm.showChildCount)?_c('span',{staticClass:"bg-slate-50 dark:bg-slate-700 rounded-full min-w-[18px] justify-center items-center flex text-xxs font-medium mx-1 py-0 px-1",class:_vm.isCountZero
              ? `text-slate-300 dark:text-slate-500`
              : `text-slate-700 dark:text-slate-50`},[_vm._v("\n          "+_vm._s(_vm.childItemCount)+"\n        ")]):_vm._e()]),_vm._v(" "),(_vm.warningIcon)?_c('span',{staticClass:"inline-flex mr-1 bg-red-50 dark:bg-red-900 p-0.5 rounded-sm"},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('SIDEBAR.REAUTHORIZE')),expression:"$t('SIDEBAR.REAUTHORIZE')",modifiers:{"top-end":true}}],staticClass:"text-xxs text-red-500 dark:text-red-300",attrs:{"icon":_vm.warningIcon,"size":"12"}})],1):_vm._e()])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }